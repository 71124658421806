
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import _ from 'lodash';
import UserService from '@/services/UserService';
import api from '@/services/api';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import ManageCollectionsModal from '@/ui/components/components/ManageCollectionsAccessModal.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import ConChecker from '@/ui/components/components/ConnectivityChecker.vue';
import DuplicationStatusIndicator from '@/ui/components/components/DuplicationStatusIndicator.vue';
import { copyRoomWithDevices, sortRoom, updateMemberOrders } from '@/utils/duplicationUtils';
import { IProjectsState } from '@/store/modules/projects/types';
import { IMPCState } from '@/store/modules/mpc/types';
import { IMemberState } from '@/store/modules/members/types';
import { IDevicesState } from '@/store/modules/devices/types';
import { IRoomsState } from '@/store/modules/rooms/types';
import { IRulesState } from '@/store/modules/rules/types';
import { IAppState } from '@/store/modules/app/types';
import { IVariableListState } from '@/store/modules/variables/types';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IRoom } from '@/types/rooms.types';
import { IProject } from '@/types/project.types';
import { Validation } from '@/ui/mixins/validation';
import { mixins } from 'vue-class-component';
import DuplicationNavigation from '@/ui/components/components/DuplicationNavigation.vue';
import { IRule } from '@/types/rules.types';
import { IDevice } from '@/types/devices.types';
import { IMPCWeatherServiceRequestBody, IMLModel } from '@/types/mpc/mpc.types';
import { UserRoleCode } from '@/utils/userRoles';
import { IReportBox } from '@/types/app.types';
import { IMeasurements } from '@/types/measurements.types';
import { IVariable } from '@/types/variables.types';
import { IMember } from '@/types/members.types';
import { IMQTTClientInfo } from '@/types/mqtt.types';

/**
 * Component that allows to create a new project
 */
@Component({
  computed: {
    UserRoleCode: () => UserRoleCode,
  },
  components: {
    DuplicationNavigation,
    ModalWindow,
    ManageCollectionsModal,
    CircleSpinner,
    ConChecker,
    DuplicationStatusIndicator,
  },
})
export default class ManageProjectDuplicateWindow extends mixins(Validation) {
  @Prop({ default: false }) isWeatherServiceActive!: boolean;
  @Prop() mqttInfo!: any;
  @Prop() projectModel!: any;

  @Getter('projects/project') project!: IProject;
  @Getter('members/currentMember') currentMember!: IMember;
  @Getter('mqttClient/mqttClientInfo') mqttClientInfo!: IMQTTClientInfo;
  @State('projects') projectsState!: IProjectsState;
  @State('mpc') mpcState!: IMPCState;
  @State('members') membersState!: IMemberState;
  @State('devices') devicesState!: IDevicesState;
  @State('rooms') roomsState!: IRoomsState;
  @Getter('rooms/rooms') rooms!: IRoom[];
  @State('rules') rulesState!: IRulesState;
  @State('app') appState!: IAppState;
  @State('variables') variablesState!: IVariableListState;
  @State('measurements') measurementsState!: IVariablesState;
  @Mutation('projects/setMqttInfo') setMqttInfo!: (info: any) => void;
  @Mutation('projects/setProject') setProject!: (payload: string) => void;
  @Mutation('app/setReport') setReport!: (payload: IReportBox) => void;
  @Mutation('projects/setProjectId') setProjectId!: (payload: string) => void;
  @Action('projects/copyProject') copyProject!: (payload: IProject) => Promise<IProject>;
  @Action('mpc/activateWeatherService') activateWeatherService!: (
    payload: IMPCWeatherServiceRequestBody,
  ) => Promise<boolean>;
  @Action('devices/fetchDevices') fetchDevices!: (payload: any) => Promise<void>;
  @Action('mpc/fetchMPCListByProject') fetchMPCListByProject!: () => Promise<void>;
  @Action('mqttClient/createConnection') createConnection!: () => Promise<void>;
  @Action('mqttClient/publish') publishStore!: any;
  @Action('variables/modifyVariableAliasList') modifyVariableAliasList!: (variable: any) => boolean;
  @Action('projects/loadProject') loadProject!: (projectId: string) => Promise<void>;
  @Action('rules/addRules') addRules!: (payload: {
    project_id: string;
    rulesList: IRule[];
  }) => Promise<IRule[]>;
  @Action('rooms/copyRoom') copyRoom!: ({
    room,
    cover,
  }: {
    room: IRoom;
    cover: any;
  }) => Promise<IRoom>;
  @Action('devices/createDevice') createDevice!: (control: IDevice) => Promise<IDevice>;
  @Action('devices/addDeviceToFavorites') addDeviceToFavorites!: (control: string) => Promise<void>;
  @Action('mpc/createMCCInstance') createMCCInstance!: (control: IMLModel) => Promise<void>;
  @Action('mpc/addMPCToFavorites') addMPCToFavorites!: (id: string) => Promise<void>;
  @Action('members/createMember') createMember!: (data: {member: IMember; showMessage: boolean}) => Promise<IMember> | string;
  @Action('members/updateMemberCollections') updateMemberCollections!: any;
  @Action('rooms/updateRoom') updateRoom!: ({
    room,
    cover,
  }: {
    room: IRoom;
    cover: any;
  }) => Promise<IRoom>;
  @Action('rules/loadRules') loadRules!: (project_id: string) => Promise<IRule[]>;
  @Action('projects/updateProject') updateProject!: (project: any) => Promise<void>;
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (
    projectId: string,
  ) => Promise<void>;
  @Action('mqttClient/removeConnection') removeConnection!: () => Promise<void>;

  stage = 1;
  autoRuleIdList: any = [];
  items = [];
  loading = false;
  search = '';
  usersForNewProject = [];
  model = null;
  roomIdMapping: any = {};
  isLoading = false;
  loadingText: any = '';
  connectivity: any = {
    actions: [],
    enabled: false,
  };
  userFilteredRuleList: any = [];
  finalRuleList: any = [];
  rulesWithRecipients = false;
  // original copy is needed in order to show the original names correctly for the user
  finalRuleListOriginal: any = [];
  timer: any = undefined;
  isDuplicationDone = false;
  duplicationStatus: any = {
    rulesStatus: [],
    memberStatus: [],
    roomStatus: [],
    devicesStatus: [],
    variablesStatus: { error: false },
    weatherServiceStatus: { error: false },
    positionStatus: { error: false },
  };
  newProjectName= '';

  oldDevicesMap: any = {}; // will contain a whole list of all Devices and MPC's with their old Id's

  handleDeleteMailSelection(ruleIndex: number, actionIndex: number, recipientIndex: any) {
    this.finalRuleList[ruleIndex].actions[actionIndex].params.recipients.splice(recipientIndex, 1);
    this.finalRuleListOriginal[ruleIndex].actions[actionIndex].params.recipients.splice(recipientIndex, 1);
  }

  get localRulesList() {
    return this.rulesState.rulesList;
  }
  get userRoleItems() {
    return [
      { name: 'Admin', value: UserRoleCode.admin },
      { name: 'User', value: UserRoleCode.user },
      { name: 'Readonly', value: UserRoleCode.readOnly },
    ];
  }

  handleChange(val: any) {
    this.connectivity = val;
  }

  handleSelectAll() {
    this.userFilteredRuleList.forEach((element: any) => {
      element.isDuplicated = true;
    });
  }

  handleStageChange2() {
    const ruleList = this.rulesState.rulesList;
    const newList: any = [];
    ruleList.forEach((currentRule: any) => {
      const helperRule = this.userFilteredRuleList.find(
        (element: any) => element.id === currentRule.id,
      );
      if (helperRule?.isDuplicated === true) {
        newList.push(currentRule);
      }
    });
    this.finalRuleList = newList;

    // checks if the rules have recipients
    // else we show a message to the user
    this.finalRuleList.forEach((rule: IRule) => {
      rule.actions.forEach((action: any) => {
        if (action.type === 'email') {
          if (!action.params.recipients) {
            return;
          }
          if (action.params.recipients.length > 0) {
            this.rulesWithRecipients = true;
          }
        }
      });
    });
    this.finalRuleListOriginal = _.cloneDeep(newList);
    this.incrementStage();
  }

  incrementStage() {
    this.stage++;
  }

  decrementStage() {
    this.stage--;
  }

  deleteMember(index: number) {
    this.usersForNewProject.splice(index, 1);
  }

  @Watch('model')
  onSelected(val: IMember | null) {
    if (val === null) return;

    // add readonly as defualt role to avoid errors when user doesnt select any role
    const newUser: any = this.items[0];
    newUser.role = UserRoleCode.readOnly;

    this.usersForNewProject.push(this.items[0]);

    // clear the autocomplete
    this.model = null;
    this.items = [];
    this.search = '';
  }

  @Watch('search')
  fetchUsers(val?: string) {
    if (this.loading || val == null) return;
    if (!this.isEmailValid(val)) {
      this.items = [];
      return;
    }

    this.loading = true;
    UserService.fetchUserByEmail(val)
      .then((res) => (this.items = res))
      .finally(() => (this.loading = false));
  }

  /**
   * Validates email
   * @param {string} s email
   */
  isEmailValid(s: string): boolean {
    // https://stackoverflow.com/a/574698
    if (s.length < 3 || s.length > 254) return false;

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(s.toLowerCase());
  }

  // clear items @blur, otherwise it will still show
  // the old result when focusing the autocomplete
  clearItems() {
    this.items = [];
    this.search = '';
  }

  async created() {
    await this.loadRules(this.$route.params.id);
    await this.fetchDevices(this.$route.params.id);
    await this.fetchMPCListByProject();
    await this.fetchVariables(this.$route.params.id);
    await this.fetchMeasurements(this.$route.params.id);
    const deviceList: any = _.cloneDeep(this.devicesState.devices).toJS();
    const mpcList: any = _.cloneDeep(this.mpcState.mpcControllers).toJS();
    this.oldDevicesMap = { ...deviceList, ...mpcList };

    this.isDuplicationDone = false;
    this.duplicationStatus = {
      rulesStatus: [],
      memberStatus: [],
      roomStatus: [],
      devicesStatus: [],
      variablesStatus: { error: false },
      weatherServiceStatus: { error: false },
      positionStatus: { error: false },
    };

    const autoRuleList: any[] = [];
    Object.values(deviceList).forEach((device: any) => {
      const isDeviceHasErrorWarning = device.data?.mappings?.ShowEvent_errorWarningState;
      if (isDeviceHasErrorWarning) {
        autoRuleList.push(device.data.meta.errorRule);
        autoRuleList.push(device.data.meta.warningRule);
      }
    });

    Object.values(mpcList).forEach((mpc: any) => {
      if (
        mpc.data.meta.controllerMappings.errorWarning &&
        mpc.data.meta.controllerMappings.errorWarning !== ''
      ) {
        autoRuleList.push(mpc.data.meta.errorRule);
        autoRuleList.push(mpc.data.meta.warningRule);
      }
    });

    this.userFilteredRuleList = this.rulesState.rulesList.map((element: any) => {
      const newElement = {
        isDuplicated: false,
        name: element.name,
        id: element.id,
      };
      return newElement;
    });

    const helperArray: any = [];
    this.userFilteredRuleList.forEach((element: any) => {
      if (autoRuleList.includes(element.id) === false) {
        helperArray.push(element);
      }
    });
    this.userFilteredRuleList = helperArray;
    const project = _.cloneDeep(this.projectsState.project);
    this.newProjectName = `${project.name} (copy)`;
  }

  async handleCancel() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
    }
    window.stop();
    this.setReport({ shouldShow: true });
    this.isLoading = false;
    this.$emit('reloadProject');
    (this.$refs.ModalWindowInstance as any).closeDialog();
  }

  closeDialog() {
    this.setReport({ shouldShow: true });
    this.resetForm();
    this.$emit('reloadProject');
    (this.$refs.ModalWindowInstance as any).closeDialog();
  }

  beforeDestroy() {
    this.setReport({ shouldShow: true });
    clearTimeout(this.timer);
  }

  async handleDuplicateProject() {
    this.setReport({ shouldShow: false });
    this.isLoading = true;
    this.$emit('disableNavigationGuard', true);
    const roomsList: any = _.cloneDeep(this.rooms);
    const measurementsList = _.cloneDeep(this.measurementsState.measurements.toJS());
    const variablesList = _.cloneDeep(this.variablesState.variables);
    const newProject: IProject = _.cloneDeep(this.projectsState.project);

    delete newProject.created_at;
    delete newProject.meta.plcLastUpdate;
    delete newProject.id;
    delete newProject.secret;
    delete newProject.meta.roomsPositions;
    delete newProject.stats;
    if (newProject.meta?.wasInstallationWizardCompleted !== undefined) {
      // will be set to false because the setup asisstend needs to be done again in duplicated project
      newProject.meta.wasInstallationWizardCompleted = false;
    }
    if (newProject.meta?.wasTenantWizardCompleted !== undefined) {
      // will be set to false because the tenant needs to be done again in duplicated project
      newProject.meta.wasTenantWizardCompleted = false;
    }
    if (newProject.meta?.loggerInformation !== undefined) {
      // will be set to false because the setup asisstend needs to be done again in duplicated project
      delete newProject.meta.loggerInformation;
    }
    if (newProject.meta?.loggerType !== undefined) {
      delete newProject.meta.loggerType;
    }
    if (newProject.meta.solarmanLoggerInfo !== undefined) {
      delete newProject.meta.solarmanLoggerInfo;
    }
    if (newProject.meta?.checkProtocol !== undefined) {
      delete newProject.meta.checkProtocol;
    }
    if (newProject.meta.hasLynusInverter !== undefined) {
      delete newProject.meta.hasLynusInverter;
    }
    if (newProject.meta.overallProductionMeasurement !== undefined) {
      delete newProject.meta.overallProductionMeasurement;
    }
    newProject.name = this.newProjectName;
    const oldMember = { ...this.currentMember };

    const oldProject = { ...this.project };
    // contains Object of duplicated Project

    const resultNewProject: any = await this.copyProject(newProject);
    this.setProject(resultNewProject);
    await this.removeConnection();
    await this.$router.push({ path: `/projects/${resultNewProject.id}/settings/general` });
    await this.loadProject(this.$route.params.id);
    this.$emit('setProjectData', resultNewProject);
    await this.createConnection();
    this.setMqttInfo(this.mqttClientInfo);

    const localProject = resultNewProject;
    localProject.connectivity = this.connectivity;
    await this.updateProject(localProject);

    // if in current project Weatherservice is active, activate Weatherservice in new Project
    // is needed for MPC Device creation
    if (this.isWeatherServiceActive) {
      const { password, username } = this.mqttInfo;
      const latitude = this.projectModel?.lat;
      const longitude = this.projectModel?.lon;

      const returnWeatherStatus = await this.activateWeatherService({
        latitude: +latitude,
        longitude: +longitude,
        username,
        password,
      });

      // return value will be false if error
      if (!returnWeatherStatus) {
        this.duplicationStatus.weatherServiceStatus = true;
      }
    }

    this.loadingText = this.$t('modals.duplicateProjectModal.loadingTexts.copyDevice');
    let mappedDevices: any = {};
    mappedDevices = await this.copyDevicesAndRoom(resultNewProject);

    this.loadingText = this.$t('modals.duplicateProjectModal.loadingTexts.roomPositions');
    await this.updateRoomPositions(mappedDevices, roomsList);

    this.loadingText = this.$t('modals.duplicateProjectModal.loadingTexts.addMember');
    await this.addMembers();

    this.loadingText = this.$t('modals.duplicateProjectModal.loadingTexts.updateMemberMeta');
    // Update members meta
    const positionStatus = await updateMemberOrders(
      oldMember,
      oldProject,
      resultNewProject,
      mappedDevices,
    );
    // return value will be false if error
    if (positionStatus === false) {
      this.duplicationStatus.positionStatus = true;
    }

    this.loadingText = this.$t('modals.duplicateProjectModal.loadingTexts.addRules');
    await this.addRulesNewProject(resultNewProject);

    this.loadingText = this.$t('modals.duplicateProjectModal.loadingTexts.copyAlias');
    await this.fetchVariables(this.$route.params.id);
    await this.copyVariables(oldProject, measurementsList, variablesList);

    this.isDuplicationDone = true;
    this.setReport({ shouldShow: true });
    this.isLoading = false;
    this.$emit('disableNavigationGuard', false);
  }

  async copyVariables(
    oldProject: IProject,
    measurementsList: IMeasurements,
    variablesList: IVariable[],
  ) {
    this.loadingText = this.$t('modals.duplicateProjectModal.loadingTexts.createMeasurements');

    // if measurement has alias it gets copied
    const aliasObject: any = {};
    Object.entries(measurementsList).map(async (currentMeasurement: any) => {
      const currentVariable: any = variablesList.find(
        (varElement: any) => varElement.variable === currentMeasurement[0],
      );
      if (currentVariable === undefined) return; // if variable is not found, skip it to avoid errors
      if (
        currentMeasurement[0].includes(`weather.${oldProject.id}`.replaceAll('-', '_')) === false
      ) {
        let canDuplicate = true;
        const regex = /\.[A-Za-z0-9]+_[A-Za-z0-9]+_[A-Za-z0-9]+_[A-Za-z0-9]+_[A-Za-z0-9]+\./i;
        canDuplicate = !regex.test(currentMeasurement[0]);
        if (canDuplicate) {
          if (currentVariable?.alias !== null) {
            aliasObject[currentVariable.variable] = currentVariable.alias;
          } else {
            aliasObject[currentVariable.variable] = null;
          }
        }
      }
    });

    const returnStatus = await this.modifyVariableAliasList({
      projectId: this.$route.params.id,
      variablesObject: aliasObject,
    });

    // return value will be false if error
    if (!returnStatus) {
      this.duplicationStatus.weatherServiceStatus = true;
    }

    return 0;
  }

  async addRulesNewProject(resultNewProject: IProject) {
    const allPromises: any = await Promise.allSettled(
      this.finalRuleList.map(async (currentRule: IRule) => {
        if (!this.autoRuleIdList.includes(currentRule.id)) {
          // create rule
          const newRule = {
            active: currentRule.active,
            actions: currentRule.actions,
            conditions: currentRule.conditions,
            name: currentRule.name,
            timeout: currentRule.timeout,
          };
          const { id } = resultNewProject;
          await this.$store.dispatch('rules/addRule', { id, ruleModel: newRule });
          return newRule;
        }
      }),
    );

    allPromises.forEach((promise: any) => {
      this.duplicationStatus.rulesStatus.push({
        name: promise.value.name,
        error: promise.status !== 'fulfilled',
      });
    });

    return 0;
  }

  async addMembers() {
    // adds new Members to project
    const allPromises: PromiseSettledResult<string | IMember>[] = await Promise.allSettled(
      this.usersForNewProject.map(async (user: any) => {
        const newUserWithoutCollection = _.cloneDeep(user);
        delete newUserWithoutCollection.collection;
        const newMember = await this.createMember({ member: newUserWithoutCollection, showMessage: false });
        // in case of error newMember.value is again structured like promise with status: 'Error' and value: IMember
        const resultMember = (newMember as any).value ?? newMember;

        if (resultMember.status !== 'Error') {
          const newCollectionArray: any = [];
          if (user?.collections !== undefined) {
            user.collections.forEach((collectionId: string) => {
              Object.entries(this.roomIdMapping).forEach((roomEntrie: any) => {
                if (collectionId === roomEntrie[0]) {
                  newCollectionArray.push(roomEntrie[1]);
                }
              });
            });
          }
          // 1000 = Admin User that doesn't need any collections to be set because he has access to every room inside project
          if (user?.role !== UserRoleCode.admin) {
            await this.updateMemberCollections({
              project_id: this.$route.params.id,
              member: resultMember,
              collectionsList: newCollectionArray,
            });
          }
        }

        return resultMember;
      }),
    );

    allPromises
      .forEach((promise: any) => {
        this.duplicationStatus.memberStatus.push({
          name: promise.value.email,
          error: promise.status !== 'fulfilled',
        });
      });

    return allPromises;
  }

  async updateRoomPositions(mappedDevices: {}, roomsList: any) {
    // eslint-disable-next-line no-underscore-dangle
    const roomMeta: any = {};
    roomsList.map(async (roomItem: any) => {
      const newElement = _.cloneDeep(roomItem);
      roomMeta[newElement.id] = await sortRoom(roomItem, newElement, mappedDevices);
    });
    return roomMeta;
  }

  async copyDevicesAndRoom(resultNewProject: string) {
    const roomsList: any = _.cloneDeep(this.rooms);
    // eslint-disable-next-line no-underscore-dangle
    const mappedDevices: any = {};
    let resultObject: { resultRoom: IRoom; resultDevices: {} };
    let allPromises: any;
    /* goes through all room objects and creates the devices for each specific room
      if/else is needed because in some projects the room object is different */
    // eslint-disable-next-line prefer-const
    allPromises = await Promise.allSettled(
      roomsList.map(async (roomItem: any) => {
        resultObject = await copyRoomWithDevices(roomItem, resultNewProject, mappedDevices);
        this.roomIdMapping[roomItem.id] = resultObject.resultRoom.id;
        return resultObject;
      }),
    );

    allPromises.forEach((promise: any) => {
      const deviceResultList: { error: boolean; name: string }[] = [];
      Object.entries(promise?.value?.resultDevices).forEach((deviceEntrie: any) => {
        const deviceName = this.oldDevicesMap[deviceEntrie[0]].name;
        deviceResultList.push({ name: deviceName, error: deviceEntrie[1] === undefined });
      });
      this.duplicationStatus.devicesStatus.push(deviceResultList);
      if (
        promise.value.resultRoom?.error === undefined &&
        !deviceResultList.some((el: { error: boolean }) => el.error === true)
      ) {
        this.duplicationStatus.roomStatus.push({
          error: false,
          name: promise.value.resultRoom.name,
        });
      } else {
        this.duplicationStatus.roomStatus.push({
          error: true,
          name: promise.value.resultRoom.name,
        });
      }
    });
    return mappedDevices;
  }

  resetForm() {
    this.stage = 1;
    this.usersForNewProject = [];
    this.finalRuleList = [];
  }

  handleSelection(value: any) {
    this.usersForNewProject.forEach((userElement: any) => {
      if (userElement.id === value.id) {
        userElement.collections = value.selections;
      }
    });
  }

  // Returns list of all basic devices by collection ID
  getDevicesByCollectionID(collection_id: string) {
    const devicesList: any = [];
    this.devicesState.devices.forEach((device: IDevice) => {
      if (device.collection_id === collection_id) {
        devicesList.push(device);
      }
    });
    return devicesList;
  }

  getMpcList(collection_id: string) {
    const mpcList: any = [];
    this.mpcState.mpcControllers.forEach((device: any) => {
      if (device.collection_id === collection_id) {
        mpcList.push(device);
      }
    });
    return mpcList;
  }
}
