import { render, staticRenderFns } from "./ManageProjectDuplicateWindow.vue?vue&type=template&id=5f6f1bc3&scoped=true&"
import script from "./ManageProjectDuplicateWindow.vue?vue&type=script&lang=ts&"
export * from "./ManageProjectDuplicateWindow.vue?vue&type=script&lang=ts&"
import style0 from "./ManageProjectDuplicateWindow.vue?vue&type=style&index=0&id=5f6f1bc3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f6f1bc3",
  null
  
)

export default component.exports